import Checkbox = require("Everlaw/UI/Checkbox");
import Dom = require("Everlaw/Dom");
import Icon = require("Everlaw/UI/Icon");
import Input = require("Everlaw/Input");
import { Arr, Is } from "core";
import Tooltip = require("Everlaw/UI/Tooltip");
import Popover = require("Everlaw/UI/Popover");
import Util = require("Everlaw/Util");
import dojo_on = require("dojo/on");
import dojo_query = require("dojo/query");
import { addEverClass, EVERCLASS } from "Everlaw/EverAttribute/EverClass";
import { getTextToHash, setEverHash } from "Everlaw/EverAttribute/EverHash";
import { EverId, setEverId } from "Everlaw/EverAttribute/EverId";
import { FocusDiv, makeFocusable } from "Everlaw/UI/FocusDiv";

/**
 * It turns out that dojo has a listener for touch devices that traps clicks for certain elements
 * and the fires off custom click logic. What gets trapped is determined by finding an ancestor of
 * the node (or the node itself) with the dojoClick property.
 *
 * In this case, the Popover has its dojoClick property set to true, which means it ultimately
 * calls preventDefault on any click event inside it. This includes our custom MenuItems.
 *
 * To get around this, we just add the dojoClick property to our MenuItem node and set it to false,
 * so a click event can continue as normal.
 *
 * NOTE: Doing this prevents touch from working in certain browsers (currently, only Chrome in
 * Windows is affected). We are hoping that dojo 2.0 will address this issue.
 */
interface DojoClickElement extends HTMLElement {
    dojoClick?: boolean;
}

class MenuItem {
    node: DojoClickElement;
    icon: HTMLElement;
    label: HTMLElement;
    connect: dojo_on.Handle;
    toggle: Checkbox;
    href: string;
    walkmeCls: string;
    toggleOnClickHandler: any;
    focusDiv: FocusDiv;
    onClick?: () => void;
    readonly selectable: boolean = false;
    private disabled = false;
    private disabledTooltip: Tooltip;
    private multiline = false;
    // whether the menu item has a checkbox toggle
    includesToggle = false;
    constructor(public item: PopoverMenu.Item) {
        item.id = item.id || item.label.toString();
        this.href = item.href;
        this.multiline = item.multiline;
        this.selectable = item.selectable;
        this.onClick = item.onClick;
        if (this.multiline) {
            this.node = Dom.div({ class: "action" });
            this.label = Dom.div({
                class: "popover-menu-item-label popover-menu-item-label-multiline",
            });
        } else {
            if (this.href) {
                this.node = Dom.a({ href: this.href });
            } else {
                this.node = Dom.span({ class: "action" });
            }
            this.label = Dom.span({ class: "popover-menu-item-label" });
            Dom.addClass(this.node, "ellipsed");
        }
        this.node.dojoClick = false;
        this.walkmeCls = "popover-menu-item-" + this.getId().toLowerCase().split(" ").join("-");
        Dom.addClass(this.node, "popover-menu-item nofocus");
        item.everId && setEverId(this.node, item.everId);
        this.includesToggle = Is.defined(item.withToggleState);
        if (this.includesToggle) {
            Dom.addClass(this.node, "popover-menu-checkbox-container");
            this.toggle = Checkbox.asToggleSlider({
                state: item.withToggleState,
                onChange: (state) => {
                    item.onToggled && item.onToggled(state);
                },
                preventDefault: true,
                disabled: this.disabled,
                label: item.label,
            });
            this.setEverHash(item.label);
        }
        Dom.setContent(
            this.node,
            this.includesToggle
                ? Dom.span({ class: "popover-menu-item-toggle" }, this.toggle.getNode())
                : null,
            (this.icon = Dom.span({ class: "popover-menu-item-icon" })),
            this.label,
        );
        this.setDisabled(!!item.disabled, item.disabledTooltip);
        if (item.iconClass) {
            Dom.addClass(this.icon, item.iconClass);
        }
        if (item.labelClass) {
            Dom.addClass(this.label, item.labelClass);
        }
        if (item.itemClass) {
            Dom.addClass(this.node, item.itemClass);
        }
        this.setIcon(item.icon);
        if (!this.includesToggle) {
            this.setLabel(item.label);
        }
        /*
         * Dojo handles touch events by capturing the native ones and then releasing its own
         * custom event in order to speed it up. This seems to have the unfortunate result of
         * preventing our anchor element links from being followable in the tooltip menu. This
         * listener manually listens for touchend events on these items and then follows the link.
         */
        this.node.ontouchend = () => {
            if (!this.isDisabled()) {
                this.onClick?.();
                if (this.href) {
                    window.location.href = this.href;
                }
            }
        };
        this.focusDiv = makeFocusable(this.node, "focus-no-space-style");
    }
    getId() {
        return this.item.id;
    }
    setIcon(iconName: string) {
        Dom.setContent(this.icon, iconName ? Dom.node(new Icon(iconName, { alt: "" })) : null);
        Dom.show(this.icon, !!iconName);
        this.item.icon = iconName;
    }
    setLabel(label: Dom.Content) {
        Dom.setContent(this.label, label);
        this.item.label = label;
        this.setEverHash(label);
    }
    toggleItemClass(on: boolean, clazz?: string | string[]) {
        Dom.toggleClass(this.node, clazz || this.item.itemClass, on);
    }
    isDisabled() {
        return this.disabled;
    }
    setDisabled(state: boolean, reason: string | HTMLElement = this.item.disabledTooltip) {
        if (this.disabled !== state) {
            this.disabled = state;
            (this.disabled ? Dom.addClass : Dom.removeClass)(this, "disabled");
        }
        if (this.href) {
            if (this.disabled) {
                Dom.removeAttr(this.node, "href");
            } else {
                Dom.setAttr(this.node, "href", this.href);
            }
        }
        if (this.disabled && reason) {
            if (this.disabledTooltip) {
                this.disabledTooltip.disabled = false;
                this.disabledTooltip.setContent(reason);
                this.item.disabledTooltip = reason;
            } else {
                this.disabledTooltip = new Tooltip(this, reason, ["before", "after"]);
            }
        } else if (this.disabledTooltip) {
            this.disabledTooltip.disabled = true;
        }
        if (this.toggle) {
            this.toggle.setDisabled(state);
        }
        this.item.disabled = this.disabled;
    }
    destroy() {
        if (this.connect) {
            this.connect.remove();
        }
        if (this.toggleOnClickHandler) {
            Util.destroy(this.toggleOnClickHandler);
            this.toggleOnClickHandler = null;
        }
        this.focusDiv && this.focusDiv.destroy();
        this.focusDiv = null;
    }
    private setEverHash(label?: Dom.Content): void {
        const textToHash = getTextToHash(label);
        textToHash && setEverHash(this.node, textToHash);
    }
}

class PopoverMenu extends Popover {
    selectable: boolean;
    multiselect: boolean;
    private enableOnCreation: boolean;
    private mirrorTooltip: boolean;
    private mirrorTooltipPos: string[];
    private menu = Dom.div();
    private iconClass: string | string[];
    private labelClass: string | string[];
    private itemSort: (a: PopoverMenu.Item, b: PopoverMenu.Item) => number;
    // section id that each item id belongs to
    private itemSections: { [id: string]: string } = {};
    private menuSections: { [id: string]: PopoverMenu.MenuSection } = {};
    private menuSectionsArr: PopoverMenu.MenuSection[] = [];
    private destroyables: Util.Destroyable[] = [];
    private itemOrder: string[] = [];
    constructor(originalParams: PopoverMenu.Params, node: HTMLElement) {
        const params = {
            orient: ["below", "below-alt", "before-centered"],
            ...originalParams, // if orient is defined in originalParams, it will override the line above
        };
        super(params, node);
        Dom.addClass(this.dialog, "popover-menu");
        params.menuItems && this.addSection({ menuItems: params.menuItems });
        params.sections
            && params.sections.forEach((section) => {
                this.addSection(section);
            });
        if (params.dialogContent) {
            this.setContent(Dom.div(params.dialogContent, this.menu));
        } else {
            this.setContent(this.menu);
        }
        addEverClass(this.menu, EVERCLASS.POPOVER_MENU);
        this.iconClass = params.iconClass;
        this.labelClass = params.labelClass;
        this.addGlobalItemClasses();
        if (params.maxWidth) {
            Dom.style(this.menu, { maxWidth: params.maxWidth });
        }
        if (!this.enableOnCreation) {
            this.updateDisplayAsDisabled();
        }
        if (params.makeFocusable) {
            this.destroyables.push(
                this._focusDiv,
                Input.fireCallbackOnKey(this._focusDiv.node, [Input.ENTER, Input.SPACE], (e) =>
                    this.openAndFocus(e),
                ),
                dojo_on(this.menu, "focusin", (evt) => {
                    if (this.scrollDiv) {
                        const target = <HTMLElement>evt.target;
                        if (Dom.hasClass(target, "focusDiv")) {
                            const parent = target.parentElement;
                            if (!this.isInView(parent)) {
                                this.scrollDiv.scrollTop =
                                    parent.offsetTop - this.scrollDiv.offsetTop;
                            }
                        }
                    }
                }),
            );
        }
    }
    openAndFocus(e: Event) {
        this.button.loadAndOpenDropDown();
        if (this.isOpen()) {
            // Need to find the first item and focus it.
            if (this.menuSectionsArr.length > 0) {
                const firstSection = this.menuSectionsArr[0];
                const childMenuItem = firstSection.getChildItem();
                childMenuItem && childMenuItem.focusDiv.focus();
            }
        }
        e.stopPropagation();
    }
    private addGlobalItemClasses() {
        this.iconClass
            && this.forAllItems((item) => {
                item.icon && Dom.addClass(item.icon, this.iconClass);
            });
        this.labelClass
            && this.forAllItems((item) => {
                item.label && Dom.addClass(item.label, this.labelClass);
            });
        this.selectable
            && this.forAllItems((item) => {
                item.selectable && Dom.addClass(item, "popover-menu-item-selectable");
            });
    }
    private updateShowSection(section: PopoverMenu.MenuSection) {
        section.setShown();
    }
    private addSection(section: PopoverMenu.Section) {
        section.itemSort = section.itemSort || this.itemSort;
        const menuSection = new PopoverMenu.MenuSection(section);
        if (section.disabledTooltipMessage) {
            new Tooltip(menuSection, section.disabledTooltipMessage);
        }
        Dom.place(menuSection, this.menu);
        this.menuSections[menuSection.id] = menuSection;
        this.menuSectionsArr.push(menuSection);
        menuSection.forAllItems((item) => {
            this.addItem(item, menuSection);
        });
        this.updateShowSection(menuSection);
    }
    private _selectItem(item: MenuItem) {
        if (item.isDisabled() || item.includesToggle) {
            return;
        }
        if (item.item.onClick) {
            item.item.onClick();
        } else {
            this._onClick(item.item);
        }
        this.close();
    }
    private addItem(item: MenuItem, section: PopoverMenu.MenuSection) {
        if (this.mirrorTooltip) {
            this.destroyables.push(
                new Tooltip.MirrorTooltip(item.label, null, this.mirrorTooltipPos),
            );
        }
        item.connect = dojo_on(item.node, Input.tap, (e) => {
            e.stopPropagation();
            this._selectItem(item);
        });
        this.itemSections[item.getId()] = section.id;
        this.destroyables.push(
            Input.fireCallbackOnKey(item.focusDiv.node, [Input.ENTER], (e) => {
                e.stopPropagation();
                this._selectItem(item);
                if (item.href && !item.isDisabled()) {
                    window.location.href = item.href;
                }
            }),
            Input.fireCallbackOnKey(item.focusDiv.node, [Input.ARROW_UP, Input.ARROW_DOWN], (e) => {
                let ind = this.itemOrder.indexOf(item.getId()) + this.itemOrder.length;
                ind = (ind + (e.key === Input.ARROW_DOWN ? 1 : -1)) % this.itemOrder.length;
                const nextId = this.itemOrder[ind];
                const nextItem = this.getItem(nextId);
                nextItem.focusDiv.focus();
            }),
        );
        this.itemOrder.push(item.getId());
        item.selectable && Dom.addClass(item, "popover-menu-item-selectable");
    }
    forAllItems(action: (item: MenuItem) => void): void {
        Object.values(this.menuSections).forEach((section) => {
            section.forAllItems(action);
        });
    }
    private forItem(id: string, action: (item: MenuItem) => void) {
        const item = this.getItem(id);
        item && action(item);
    }
    // Assumes item exists
    private getSectionForItem(id: string) {
        return this.getSection(this.itemSections[id]);
    }
    getSection(id: string) {
        return this.menuSections[id];
    }
    getItem(id: string) {
        if (this.hasItem(id)) {
            return this.getSectionForItem(id).getItem(id);
        }
    }
    getIndexOfItem(id: string) {
        return this.itemOrder.indexOf(id);
    }
    private hasItem(id: string) {
        return id in this.itemSections;
    }
    private addItemAndSortSection(section: PopoverMenu.MenuSection, elem: PopoverMenu.Item) {
        section.addItemAndSort(elem);
        section.forAllItems((item) => {
            this.addItem(item, section);
        });
    }
    private sortSection(id: string) {
        if (this.hasItem(id)) {
            const section = this.getSectionForItem(id);
            if (section.itemSort) {
                this.addItemAndSortSection(section, null);
            }
        }
    }
    setItemLabel(id: string, label: string) {
        this.forItem(id, (item) => item.setLabel(label));
        this.sortSection(id);
    }
    toggleItemClass(id: string, on: boolean, clazz?: string | string[]) {
        if (this.hasItem(id)) {
            this.getSectionForItem(id).toggleItemClass(id, on, clazz);
        }
    }
    setItemIcon(id: string, iconName?: string) {
        this.forItem(id, (item) => item.setIcon(iconName));
        this.sortSection(id);
    }
    select(id?: string) {
        if (!this.multiselect) {
            this.deselectAll();
        }
        id
            && this.forItem(id, (item) => {
                if (item.selectable) {
                    Dom.addClass(item, "popover-menu-item-selected");
                    item.setIcon("check-blue-20");
                }
            });
    }
    deselect(id?: string): void {
        id
            && this.forItem(id, (item) => {
                if (item.selectable) {
                    Dom.removeClass(item, "popover-menu-item-selected");
                    item.setIcon("");
                }
            });
    }
    deselectAll() {
        this.forAllItems((item) => item.selectable && item.setIcon(""));
        dojo_query(".popover-menu-item-selected", this.menu).removeClass(
            "popover-menu-item-selected",
        );
    }
    isSelected(id: string) {
        return Dom.hasClass(this.getItem(id), "popover-menu-item-selected");
    }
    showItem(id: string, show: boolean) {
        Dom.show(this.getItem(id), show);
    }
    setItemDisabled(id: string, disabled: boolean, reason?: string | HTMLElement) {
        if (this.hasItem(id)) {
            this.getSectionForItem(id).setItemDisabled(id, disabled, reason);
            this.updateDisplayAsDisabled();
        }
    }
    add(elem: PopoverMenu.Item, sectionId?: string, idx?: number) {
        if (!this.getItem(elem.id)) {
            let section = this.menuSections[sectionId];
            if (!section) {
                section = this.menuSections[PopoverMenu.MenuSection.DEFAULT_ID];
            }
            if (!idx && section.itemSort) {
                this.addItemAndSortSection(section, elem);
            } else {
                this.addItem(section.addItem(elem, idx), section);
            }
            this.updateShowSection(section);
            this.addGlobalItemClasses();
            this.updateDisplayAsDisabled();
        }
    }
    remove(id: string) {
        if (this.hasItem(id)) {
            const section = this.getSectionForItem(id);
            section.deleteItem(id);
            delete this.itemSections[id];
            this.itemOrder.splice(this.getIndexOfItem(id), 1);
            this.updateDisplayAsDisabled();
            this.updateShowSection(section);
        }
    }
    /* If all items are disabled, we gray out the menu icon.
     * The user can still expand the menu to see its contents. */
    private updateDisplayAsDisabled() {
        const allDisabled = Object.values(this.menuSections).every((s) => s.isDisabled());
        Dom.toggleClass(this.node, "display-as-disabled", allDisabled);
    }
    private _onClick(item: PopoverMenu.Item) {
        if (this.selectable) {
            this.select(item.id);
        }
        this.onClick(item);
    }
    onClick(item: PopoverMenu.Item) {}
    override destroy() {
        super.destroy();
        this.destroyables.push(...Object.values(this.menuSections));
        Util.destroy(this.destroyables);
        this.destroyables = [];
    }
    private isInView(elem): boolean {
        const elemTop = elem.offsetTop - this.scrollDiv.offsetTop;
        const elemBot = elemTop + elem.scrollHeight;
        const viewTop = this.scrollDiv.scrollTop;
        const viewBot = viewTop + this.scrollDiv.clientHeight;
        return elemTop > viewTop && elemBot < viewBot;
    }
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module PopoverMenu {
    export interface Section {
        header?: string;
        headerClass?: string;
        /**
         * If not provided, defaults to the header, and then `DEFAULT_ID`. Note that each Section should
         * have a unique id, so for `PopoverMenu`s with multiple `Section`s a header or id value must be
         * provided for each `Section`.
         */
        id?: string;
        menuItems: Item[];
        itemSort?: (a: Item, b: Item) => number;
        disabled?: boolean;
        disabledTooltipMessage?: string;
        background?: any;
        hideIfEmpty?: boolean;
        class?: string;
    }

    export interface Item {
        label: Dom.Content;
        /**
         * If provided, overrides the onClick method in the accompanying PopoverMenu.
         */
        onClick?: () => void;
        icon?: string;
        id?: string;
        /**
         * If this item redirects to another page, provide this field instead of redirecting manually
         * in onClick.
         */
        href?: string;
        // extra classes for the icon
        iconClass?: string | string[];
        // extra classes for the label
        labelClass?: string | string[];
        disabled?: boolean;
        disabledTooltip?: string | HTMLElement;
        // extra classes for the item
        itemClass?: string;
        withToggleState?: boolean;
        onToggled?: (state) => void;
        multiline?: boolean;
        selectable?: boolean;
        everId?: EverId;
    }

    export interface Params extends Popover.Params {
        /**
         * Labelless menu items at the top of the PopoverMenu.
         */
        menuItems?: Item[];
        /**
         * Sections of Items to be added below the initial menu items.
         */
        sections?: Section[];
        /**
         * Comparator function for ordering Items within every section, including the section
         * at the top of the PopoverMenu. Set Section.itemSort to override with section-specific
         * sorting.
         */
        itemSort?: (a: Item, b: Item) => number;
        /**
         * If any of the menu items has an onClick method, will use that in place of this one.
         */
        onClick?: (item: Item) => void;
        /**
         * When the Popover for this menu is opened.
         */
        onOpen?: () => void;
        /**
         * When the Popover for this menu is closed.
         */
        onClose?: (fromButton?: boolean) => void;
        /**
         * Can a menu item be "selected" (will remain selected on subsequent views)?
         */
        selectable?: boolean;
        multiselect?: boolean;

        /**
         * should we blur the parent node of the popover on creation?
         */
        enableOnCreation?: boolean;
        /**
         * Should ellipsified menu items have tooltips displaying the full name?
         */
        mirrorTooltip?: boolean;
        mirrorTooltipPos?: string[];
        // additional classes that get applied to each individual menu item
        iconClass?: string | string[];
        labelClass?: string | string[];
        premenuContent?: Dom.Content;
        maxWidth?: string;
    }

    export class MenuSection {
        static DEFAULT_ID = "default";
        node = Dom.div({ class: "popover-menu-section" });
        header: HTMLElement;
        id: string;
        itemSort: (a: PopoverMenu.Item, b: PopoverMenu.Item) => number;
        private disabled: boolean;
        private menuItems: { [id: string]: MenuItem } = {};
        private hideIfEmpty: boolean;
        constructor(section: PopoverMenu.Section) {
            section.class && Dom.addClass(this.node, section.class);
            this.id = section.id || section.header || MenuSection.DEFAULT_ID;
            if (section.header) {
                let headerClass = "popover-menu-header";
                if (section.headerClass) {
                    headerClass = headerClass + " " + section.headerClass;
                }
                this.header = Dom.create(
                    "div",
                    {
                        class: headerClass,
                        content: section.header,
                    },
                    this.node,
                );
            }
            this.itemSort = section.itemSort;
            if (this.itemSort) {
                Arr.sort(section.menuItems, { cmp: this.itemSort });
            }
            section.menuItems.forEach((itemParams) => {
                this.addItem(itemParams);
            });
            if (section.disabled) {
                this.forAllItems((item) => item.setDisabled(true));
            }
            this.updateDisabled();
            section.background && Dom.style(this.node, { background: section.background });
            this.hideIfEmpty = section.hideIfEmpty;
        }
        setShown() {
            if (!this.hideIfEmpty) {
                Dom.show(this.node);
                return;
            }
            // We're hiding this section if it's empty --> hide it if every item in it is hidden
            if (Object.keys(this.menuItems).length === 0) {
                Dom.hide(this.node);
                return;
            }
            Dom.hide(
                this.node,
                Object.keys(this.menuItems).every((id) => Dom.isHidden(this.menuItems[id])),
            );
        }
        addItemAndSort(itemParams: PopoverMenu.Item) {
            const newMenuItems: PopoverMenu.Item[] = Object.values(this.menuItems).map(
                (k) => k.item,
            );
            itemParams && newMenuItems.push(itemParams);
            Arr.sort(newMenuItems, { cmp: this.itemSort });
            this.forAllItems((item: MenuItem) => this.deleteItem(item.getId()));
            newMenuItems.forEach((itemParams) => {
                this.addItem(itemParams);
            });
        }
        addItem(itemParams: PopoverMenu.Item, idx?: number) {
            const item = new MenuItem(itemParams);
            Dom.addClass(this.node, item.walkmeCls);
            Dom.place(item, this.node, idx);
            this.menuItems[item.getId()] = item;
            this.updateDisabled();
            return item;
        }
        deleteItem(id: string) {
            const item = this.menuItems[id];
            Dom.hide(item);
            Util.destroy(item);
            Dom.removeClass(this.node, item.walkmeCls);
            delete this.menuItems[id];
            this.updateDisabled();
        }
        forAllItems(action: (item: MenuItem) => void) {
            Object.values(this.menuItems).forEach(action);
        }
        getItem(id: string) {
            return this.menuItems[id];
        }
        toggleItemClass(id: string, on: boolean, clazz?: string | string[]) {
            this.getItem(id).toggleItemClass(on, clazz);
        }
        isDisabled() {
            return this.disabled;
        }
        setItemDisabled(id: string, disabled: boolean, reason?: string | HTMLElement) {
            this.getItem(id).setDisabled(disabled, reason);
            this.updateDisabled();
        }
        private updateDisabled() {
            const allDisabled = Object.values(this.menuItems).every((s) => s.isDisabled());
            if (allDisabled !== this.disabled) {
                this.header && Dom.style(this.header, { opacity: allDisabled ? "0.5" : "" });
                this.disabled = allDisabled;
            }
        }
        destroy() {
            Util.destroy(Object.values(this.menuItems));
        }
        getChildItem(): MenuItem | undefined {
            const itemKeys = Object.keys(this.menuItems);
            if (itemKeys.length > 0) {
                for (let i = 0; i < itemKeys.length; i++) {
                    if (!Dom.isHidden(this.menuItems[itemKeys[i]])) {
                        return this.menuItems[itemKeys[i]];
                    }
                }
                return null;
            } else {
                return null;
            }
        }
    }

    /**
     * Creating a PopoverMenu is time-consuming (~10ms). Most of this time is spent in dojo,
     * so short of rewriting it all we can do is work around it by creating it lazily.
     *
     * This wrapper around PopoverMenu lazily creates a PopoverMenu when the mouse is hovered over
     * the trigger element, or it is focused by keyboard navigation. Use LazyPopoverMenu when the
     * page may have a large/arbitrary number of popover menus to avoid creating too many at once.
     */
    export class LazyPopoverMenu {
        menu: PopoverMenu | null = null;
        node: HTMLElement;
        focusDiv: FocusDiv;
        private readonly getMenuParams: () => PopoverMenu.Params;
        private toDestroy?: Util.Destroyable[];
        constructor(
            getMenuParams: () => PopoverMenu.Params,
            node: HTMLElement,
            suppressDojoEvent?: "tap" | "press",
            toDestroy?: Util.Destroyable[],
        ) {
            this.node = node;
            // Create focusDiv for the menu trigger and pass it into the PopoverMenu. Usually, Popover
            // creates the focusDiv for the trigger node, but since the PopoverMenu is created lazily,
            // the trigger won't initially have a focusDiv.
            this.focusDiv = makeFocusable(this.node, "focus-with-space-style");
            this.getMenuParams = getMenuParams;
            this.toDestroy = toDestroy;
            if (suppressDojoEvent) {
                const listener = dojo_on(
                    this.node,
                    suppressDojoEvent === "tap" ? Input.tap : Input.press,
                    (e) => {
                        e.stopPropagation();
                    },
                );
                this.toDestroy?.push(listener);
            }
            // We need to use a dojo listener for Input.enter here, since that's the event that the
            // dojo tooltip listens for. Adding a listener for the mouseenter event and then
            // re-dispatching that event won't trigger the tooltip.
            dojo_on.once(this.node, Input.enter, (e) => {
                this.createMenu(e);
            });
            this.focusDiv.node.addEventListener(
                "focus",
                (e) => {
                    this.createMenu(e);
                },
                { once: true },
            );
        }
        createMenu(event?: Event) {
            if (!this.menu) {
                this.menu = new PopoverMenu(
                    {
                        ...this.getMenuParams(),
                        makeFocusable: true,
                        customFocusDiv: this.focusDiv,
                    },
                    this.node,
                );
                this.toDestroy?.push(this.menu);
                // Re-dispatch the event in order to start the tooltip timer.
                if (event) {
                    this.menu.tooltip && setTimeout(() => this.node.dispatchEvent(event));
                }
            }
        }
    }
}

export = PopoverMenu;
